import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';

const Container = styled.div`
  margin: 0 auto 3rem;
  width: 90%;
  position: relative;
  .listItem{
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 500;
  }
`;

const SubtitleH3 = styled.h3`
  font-weight: 300;
  font-size: 25px;
`;

const DotNumberList = styled.span`
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 25px;
  color: ${({ theme }) => theme.primaryColor};
  margin-right: 15px;
  font-style: italic;
`;

export default function Convenzioni() {

  return (
    <Layout pageTitle="Podcast - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
      <Fade left duration={1000}>
        <h1>Podcast</h1>
      </Fade>
      <Container>
      <iframe src='https://widget.spreaker.com/player?show_id=6057666&theme=dark&playlist=show&playlist-continuous=true&chapters-image=true' width='100%' height='400px' frameborder='0'></iframe>
      </Container>
    </Layout>
  )
}